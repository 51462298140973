<!--
 * @Author: 月魂
 * @Date: 2021-03-23 09:50:31
 * @LastEditTime: 2021-07-06 14:01:08
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\My.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="我的"
      fixed
      placeholder
    />
    <div class="info">
      <img :src="user.avatar" class="avatar">
      <div class="nickname">{{ user.nickName }}</div>
    </div>
    <div class="self" v-for="item in menu" :key="item.id" @click="handleJump(item.path)">
      <img :src="require('../assets'+ item.path + '.svg')" class="icon" >
      <span>{{ item.name }}</span>
    </div>
    <div class="edition">版本号 0.0.2</div>
    <van-tabbar route>
      <!-- <van-tabbar-item replace to="/" icon="home-o">购票</van-tabbar-item> -->
      <van-tabbar-item replace to="/" icon="idcard">购卡</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/brand" icon="friends-o">品牌</van-tabbar-item> -->
      <van-tabbar-item replace to="/my" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Tabbar, TabbarItem } from 'vant';
import { getParam } from '../utils/index'
Vue.use(NavBar).use(Tabbar).use(TabbarItem);
const info = localStorage.getItem('userInfo');

export default {
  name: 'My',
  data() {
    return {
      user: info ? JSON.parse(info) : {
        avatar: '',
        nickName: ''
      },
      menu: [
        {
          id: 1,
          name: '个人信息',
          path: '/personal',
        },
        {
          id: 2,
          name: '我的订单',
          path: '/order',
        },
        // {
        //   id: 3,
        //   name: '我的票夹',
        //   path: '/ticketHolder',
        // },
        {
          id: 4,
          name: '我的卡包',
          path: '/cardBag',
        },
        // {
        //   id: 5,
        //   name: '我的券包',
        //   path: '/couponHolder',
        // },
      ],
    };
  },
  mounted() {
    // 此处判断本地是否有用户信息，没有则通过code进行解析
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let token = localStorage.getItem('token')
    if (!token) {
      this.$router.push('/')
    }
    if (!userInfo) {
      let code = getParam('code', window.location.href);
      if (code) {
        // 此处拿code交给后台获取用户信息
        this.$store.dispatch('user/getUserInfo', code).then((res) => {
          if (res.code !== 0) return
          // 获取用户信息
          const { origin } = window.location;
          window.location.replace(`${origin}/#/my`)
        })
      } else { // 不存在code在发起授权
        // let appid = 'wx349a282b122e7e2a' // 开发（云通文投）
        let appid = 'wx4383e2b399a1fa87' // 生产（儿戏）
        // let redirectUrl = 'https://api.sxqinwen.com/#/my' // 开发
        let redirectUrl = 'https://ex.topartsintermational.com/#/my' // 生产
        window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`)
      }
    }
  },
  methods: {
    handleJump(path) {
      this.$router.push(path)
    }
  },
}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  background-color: #f9f9f9;
    .info {
    box-sizing: border-box;
    height: 96px;
    background-color: #fff;
    padding-left: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50% 50%;
      margin-right: 10px;
    }
    .nickname {
      font-size: 18px;
      color: #333;
    }
  }
  .self {
    height: 60px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
    color: #333;
    .icon {
      margin-right: 10px;
      width: 22px;
      height: 22px;
    }
  }
  .edition {
    padding: 32px;
    font-size: 14px;
    text-align: center;
    color: #7f7f7f;
  }
}
</style>
